import BaseModel from '@/libs/base/dataModel/BaseModel';

export class OfficeAnalysisDataModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 办事处名称
    this.officeName = '';
    // 小区数量
    this.communityNum = '';
    // 充电桩总数
    this.enabledDeviceNum = '';
    // 端口使用率
    this.portUseRate = '';
    // 端口总数
    this.portNum = '';
    // 有效充电订单数
    this.availableOrderNum = '';
    // 有效充电订单消费金额
    this.availableOrderAmount = '';
    // 用户数量
    this.customerNum = '';
    // 平均充电次数
    this.customerChargeAvgNum = '';
    // 客单价
    this.customerAvgAmount = '';
    // 人工退款订单数
    this.manualRefundOrderNum = '';
    // 人工退款订单金额
    this.manualRefundOrderAmount = '';
    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    this.portUseRate = Number((resData.portUseRate * 100).toFixed(2));
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        officeName: BaseModel.getFakeTitle(8),
        communityNum: BaseModel.getFakeNumber().toFixed(0),
        enabledDeviceNum: BaseModel.getFakeNumber().toFixed(0),
        portUseRate: i % 3,
        portNum: BaseModel.getFakeNumber().toFixed(0),
        availableOrderNum: BaseModel.getFakeNumber().toFixed(0),
        availableOrderAmount: BaseModel.getFakeNumber(),
        customerNum: BaseModel.getFakeNumber().toFixed(0),
        customerChargeAvgNum: BaseModel.getFakeNumber().toFixed(0),
        customerAvgAmount: BaseModel.getFakeNumber(5),
        manualRefundOrderNum: BaseModel.getFakeNumber().toFixed(0),
        manualRefundOrderAmount: BaseModel.getFakeNumber()
      };
      list.push(dic);
    }
    return list;
  }
}

<!--
    Created by 程雨喵'mac on 2024/11/28.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    页面名称：办事处经营报表
-->
<style lang="less">
#office-analysis {

}
</style>

<template>
  <div id="office-analysis">
    <yg-split-vertical-box
      v-model="isOpen"
      :show-open-button="tableData.length > 0"
    >
      <div
        slot="left"
        class="split-box"
      >
        <!-- 查询条件 -->
        <div v-permission="'system:statis:office:list'">
          <yg-search-area
            :show-info="false"
            :value="searchOption"
            :is-stealth="true"
            :show-rest="false"
            @on-search="_clickSearch"
            @on-change-stealth="tableResizeFlag = !tableResizeFlag"
          />
        </div>
        <yg-simple-table
          v-permission="'system:statis:office:list'"
          :control-resize="tableResizeFlag"
          :top-columns="tableTopColumns"
          :columns="tableColumns"
          :top-title="'办事处经营报表'"
          :export-condition="settingExportCondition"
          :export-setting="settingExportItem"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
      <div
        slot="right"
        class="right-box"
      >
        <v-charts-analysis
          v-if="isOpen"
        />
      </div>
    </yg-split-vertical-box>
  </div>
</template>

<script>
import DataStatisticsApiSet from '@/request/api/DataStatisticsApiSet';
import { OfficeAnalysisDataModel } from './model/OfficeAnalysisDataModel';
import vChartsAnalysis from '@/pages/106__dataStatistics/_components/charts/index.vue';
import ColumnMixin from './mixin/tableColumn';
import { getLastDate } from '@/libs/utils';

export default {
  components: { vChartsAnalysis },
  mixins: [ColumnMixin],
  data () {
    return {
      searchOption: {
        time: BaseSearchModel.initData('统计日期范围', 'time', 'DatePickerRange').setDefault([getLastDate(1), getLastDate(1)]).setClearable(false)
      },
      settingExportCondition: {},
      settingExportItem: BaseSettingModel.initData('导出列表', 'download', 'md-cloud-download').setExport().setExportReportType(38).setPermission('system:statis:office:export'),
      isOpen: false,
      page: { current: 1, total: 0 },
      tableData: [],
      // table重新渲染
      tableResizeFlag: false
    };
  },
  created () {},
  mounted () {
    console.log(this.$el.clientWidth);
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    getRequestParams () {
      const s = this.searchOption;
      // const searchStartTime = new Date(s.time.value[0]).getTime();
      // const earliestTime = new Date('2024-04-01 00:00:00').getTime();
      const searchEndTime = new Date(s.time.value[1]).getTime();
      const deadlineDate = getLastDate(1);
      const deadlineTime = new Date(deadlineDate + ' 23:59:59').getTime();
      // // 最早时间为24年4月1日，最晚时间为昨日
      // if (searchStartTime < earliestTime) {
      //   return {
      //     status: false,
      //     msg: '开始日期最早为2024年04月01日',
      //     params: {}
      //   };
      // }
      if (searchEndTime > deadlineTime) {
        return {
          status: false,
          msg: '结束日期最晚为' + getLastDate(1),
          params: {}
        };
      }
      return {
        status: true,
        msg: '',
        params: {
          statisDateBegin: s.time.value[0] || '',
          statisDateEnd: s.time.value[1] || ''
        }
      };
    },
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {},
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      const paramsData = this.getRequestParams();
      if (!paramsData.status) {
        return this.$Message.warning(paramsData.msg);
      }

      const api = DataStatisticsApiSet.getOfficeList;
      api.params = paramsData.params;
      api.params.pageNo = this.page.current;
      api.params.pageSize = 200;

      this.settingExportCondition = api.params;

      this.tableData = [];
      this.page.total = 0;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = OfficeAnalysisDataModel.initListWithDataList(resData.list);
      });
      // setTimeout(() => {
      //   const resList = OfficeAnalysisDataModel.createFakeDataList(200);
      //   this.page.total = resList.length * 2;
      //   this.tableData = OfficeAnalysisDataModel.initListWithDataList(resList);
      // }, 300);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
